import React, {useEffect} from 'react';
import styled from "styled-components";
import {Link, useLocation} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import InnerBanner from "../../components/InnerBanner";
import Overview from "../../components/product/Overview";
import LayerBox from "../../components/product/LayerBox";
import ProductsList from "../../components/product/ProductsList";
import Satisfaction from "../../components/Satisfaction";
import Feature from "../../components/product/Feature";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchProductsReplica} from "../../api/redux/ProductsReplica";
import {fetchProductsPackaging} from "../../api/redux/ProductsPackaging";
import ServiceTab from "../../components/product/ServiceTab";



const MyComponent = () => {
    const location = useLocation();
    const url = location.pathname.split('/')[1];


    const dispatch = useDispatch()

    let getPost;

    useEffect(() => {
        let api_url;

        if (url === 'kalyar-replica') {
            api_url = apiEndPoints.PRODUCT_REPLICA;
            dispatch(fetchProductsReplica([api_url]));
        } else {
            api_url = apiEndPoints.PRODUCT_PACKAGING;
            dispatch(fetchProductsPackaging([api_url]));
        }
    }, [dispatch, url]);

    getPost = useSelector(state => {
        if (url === 'kalyar-replica') {
            return state.productsReplicaReducer;
        } else {
            return state.productsPackagingReducer;
        }
    });

    //refactor
    let data = getPost?.posts?.[0];
    let banner = data?.banner;
    let overview = data?.overview;
    let layer = data?.layers;
    let product = data?.product;
    let research = data?.research;
    let tab = data?.tab;
    let services = data?.services;

    console.log('data', data)
    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${data?.banner?.title ? data?.banner?.title + ' | ' + data?.banner?.subtitle : ''}`}</title>
                {
                    data?.meta?.meta_desc &&
                    <meta name="description" content={data?.meta?.meta_desc}/>

                }
                {
                    data?.meta?.og_title &&
                    <meta property="og:title" content={data?.meta?.og_title}/>

                }
                {
                    data?.meta?.og_desc &&
                    <meta property="og:description" content={data?.meta?.og_desc}/>
                }
                <meta property="og:url" content={window?.location?.href}/>
                <meta property="og:image" content={banner?.image}/>
                <meta property="og:type" content={"website"}/>
            </Helmet>

            <StyledComponent>

                    <InnerBanner
                        title={banner?.title}
                        img={banner?.image}
                        subtitle={banner?.subtitle}
                    />

                    {/*<Overview data={overview}/>*/}

                    {/*<LayerBox data={layer}/>*/}
                    <ServiceTab data={services}/>

                    <ProductsList data={product}/>

                {
                    research &&
                    <Satisfaction
                        img={research?.image}
                        title={research?.title}
                        text={research?.description}
                    />
                }
                    <Feature data={tab}/>

            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;

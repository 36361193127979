import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import Title from "../Title";
import Button from "../Button";
import {BsChevronLeft, BsChevronRight} from 'react-icons/bs';
import {useEffect, useRef, useState} from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, Mousewheel, Navigation, Pagination} from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import VisibilitySensor from "react-visibility-sensor";
import {Link} from "react-router-dom";
import {Img} from "../Img";
import {book, hover} from "../../styles/globalStyleVars";
import SubTitle from "../SubTitle";
import reactHtmlParser from "react-html-parser";

const RandomSliderV1 = ({data}) => {
    // SwiperCore.use([Autoplay]);
    let leftRef = useRef();
    let rightRef = useRef();
    let mLeftRef = useRef();
    let mRightRef = useRef();
    let containerRef = useRef();
    let sliderRef = useRef();

    let [offset, setOffset] = useState(90)
    let [theWidth, SetTheWidth] = useState(0)
    let [activeNumber, setActiveNumber] = useState(1)
    let [totalNumber, setTotalNumber] = useState(1)




    useEffect(() => {

        mLeftRef.current.addEventListener('click', () => {
            if (document.querySelector('.swiper-button-prev')) {
                document.querySelector('.swiper-button-prev').click()
            }
        });
        mRightRef.current.addEventListener('click', () => {
            if (document.querySelector('.swiper-button-next')) {
                document.querySelector('.swiper-button-next').click()
            }

        });
        setOffset(document.querySelector(' .container').offsetLeft)

    }, [])


    useEffect(() => {
        setOffset(containerRef.current?.offsetLeft)
        window.addEventListener('resize', () => {
            setOffset(containerRef.current?.offsetLeft)
            SetTheWidth(window.innerWidth)
        })

        // slider number
        const getActiveItem = document.querySelector('.swiper-pagination-current')?.innerHTML
        const getTotalItem = document.querySelector('.swiper-pagination-total')?.innerHTML
        setActiveNumber(getActiveItem)
        setTotalNumber(getTotalItem)
    }, [data])


    useEffect(() => {
        // document.querySelector('.swiper-container').style.paddingLeft = offset + 'px'
    }, [offset, sliderRef])


    useEffect(() => {
        if (sliderRef.current) {
            const swiperInstance = sliderRef.current.swiper;
            if (swiperInstance) {
                setTotalNumber(swiperInstance.slides.length);
            }
        }
    }, [data])
    const [swiperIndex, setSwiperIndex] = useState(0);

    const handleSlideChange = (swiper) => {
        setSwiperIndex(swiper.realIndex);
    };

    console.log('data', data)

    return (
        <StyledBlog offset={offset} className='blog-slider pb-150 pt-150'>
            <Container ref={containerRef}>
                <Row>
                    <Col sm={12}>
                        <div className="blog-title">
                            <SubTitle text={'Media Center'} color={'rgb(24 21 58 / 80%)'} borderColor={'rgb(24 21 58 / 80%)'}/>
                            <Title classname={'split-up'} margin={'0 0 50px 0'} text={'Stay Upto Date'} color={'#262630'}/>
                        </div>
                    </Col>
                </Row>
            </Container>

            <VisibilitySensor offset={{top: -150}}>
                    <div className={` fade-up blog-slider__slider-wrap`}>
                        <div className="blog-slider__slider-wrap__inner">
                            {data &&
                            <Swiper

                                loop={false}
                                slidesPerView={3}
                                spaceBetween={30}
                                speed='1500'
                                slidesPerGroup={1}
                                keyboardControl={true}
                                modules={[Autoplay, Pagination, Navigation, Mousewheel]}
                                navigation
                                autoplay={{ delay: 3000 }}
                                onSlideChange={handleSlideChange}
                                pagination={{
                                    type: 'fraction',
                                }}
                                breakpoints={{
                                    320: {
                                        slidesPerView: 1,
                                        spaceBetween: 30,

                                    },
                                    768: {
                                        slidesPerView: 2,
                                        spaceBetween: 30,

                                    },
                                    1024: {
                                        slidesPerView: 3,
                                        spaceBetween: 30,
                                    },
                                }}
                                ref={sliderRef}
                                className="mySwiper"
                            >
                                {data?.length > 0 && data?.map((i, index) => {

                                    const dateObject = new Date(i?.list?.date);
                                    const getDate = dateObject && !isNaN(dateObject) ? dateObject.getDate() : null;
                                    const getMonth = dateObject && !isNaN(dateObject) ? dateObject.toLocaleString('default', { month: 'long' }) : null;
                                    const getYear = dateObject && !isNaN(dateObject) ? dateObject.getFullYear() : null;

                                    return (
                                        <SwiperSlide key={index}>
                                            <div className='blog-single fade-up'>
                                                <div className="blog-single__inner">
                                                    <Link to={`/media-center/${i?.post_name}`}></Link>
                                                    <Img src={i?.list?.thumbnail} objectFit={'cover'} layout={'fill'}/>
                                                    <div className="blog-single__inner__content">
                                                        <div className="blog-single__inner__content__top">
                                                            <p>{reactHtmlParser(i?.list?.short_desc)}</p>
                                                            <h4>{reactHtmlParser(i?.post_title)}</h4>

                                                        </div>
                                                        <div className="blog-single__inner__content__bottom">
                                                            <h2>{getDate}</h2>
                                                            <p>{getMonth}
                                                                <span>{getYear}</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    );

                                })}

                            </Swiper>
                            }
                        </div>

                        <div className="slider-nav-mobile">
                            <ul>
                                <li className='hover' ref={mLeftRef}><BsChevronLeft/></li>
                                <li className='hover' ref={mRightRef}><BsChevronRight/></li>
                            </ul>
                        </div>
                    </div>
            </VisibilitySensor>
        </StyledBlog>
    );
};

const StyledBlog = styled.section`
  .blog-title {
    position: relative;
    p {
      position: absolute;
      top: 0;
      right: 0;

    }

  }

  .blog-button {
    display: flex;
    justify-content: space-between;
    margin-bottom: 85px;
    align-items: center;

    .slider-nav {
      ul {
        display: flex;
      }

      li {
        height: 50px;
        width: 50px;
        background-color: black;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        cursor: pointer;

        &:nth-of-type(1) {
          margin-right: 20px;
        }

        svg {
          color: #ffffff;
          z-index: 2;
        }
      }
    }
  }

  .swiper-button-next, .swiper-button-prev {
    position: absolute;
    height: 40px;
    width: 40px;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 99;
    background-color: red;
  }

  .swiper-button-next, .swiper-button-prev, .swiper-pagination {
    opacity: 0;
    visibility: hidden;
  }

  .swiper-initialized {
    margin-left: ${props => props.offset + 15}px;
    padding-right: ${props => props.offset + 15}px;
  }

  .slider-nav-mobile {
    display: none;
  }

  .blog-slider {
    &__slider-wrap {
      &__inner {
        .blog-single {
          &__inner {
            padding-top: 115%;
          }
        }
      }
    }
  }

  .blog-single__inner {
    padding-top: calc(460 / 370 * 100%);
    position: relative;
    &:after{
      content: '';
      height: 0;
      width: 100%;
      background-color: #18153A;
      top: 0;
      left: 0;
      right: 0;
      position: absolute;
      transition: height .4s ease;
    }
    
    a {
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      z-index: 3;
    }

    &__content {
      //&:after {
      //  content: '';
      //  height: 100%;
      //  width: 100%;
      //  background-color: #E1E4E6;
      //  top: 0;
      //  left: 0;
      //  right: 0;
      //  position: absolute;
      //  transition: height .4s ease;
      //}

      &__top {
        p {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          color: #9F9FBF;
          position: absolute;
          left: 40px;
          top: 40px;
          z-index: 2;
          right: 40px;
          margin: 0;
          transform: translateY(-30px);
          opacity: 0;
          transition: all .6s ease;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
        }

        h4 {
          position: absolute;
          top: 40px;
          left: 40px;
          right: 40px;
          z-index: 2;
          //font-size: 26px;
          //font-weight: bold;
          //line-height: 32px;
          color: #E9E9E9;
          margin: 0;
          transition: all .3s ease;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
        }
      }

      &__bottom {
        position: absolute;
        margin: 0;
        left: 40px;
        right: 40px;
        bottom: 24px;
        display: flex;
        justify-content: space-between;
        border-top: 1px solid rgb(255 255 255 / 50%);
        padding-top: 20px;
        z-index: 2;

        h2 {
          font-size: 48px;
          font-weight: 400;
          color: #E9E9E9;
          line-height: 62px;
          //transition: color .3s ease;
        }

        p {
          font-size: 14px;
          color: #E9E9E9;
          text-align: right;
          font-weight: ${book};
          line-height: 20px;
          transition: color .3s ease;

          span {
            font-size: 14px;
            line-height: 20px;
            font-weight: ${book};
            display: block;
            color: #E9E9E9;
          }
        }
      }
    }

    &:hover {
      .blog-single__inner__content:after {
        height: 0;
      }
      .blog-single__inner__content__top {
        h4 {
          opacity: 0;
          transform: translateY(-20px);
        }

        p {
          transform: none;
          opacity: 1;
        }
      }

      .blog-single__inner__content__bottom {
        border-color: rgb(255 255 255 / 50%);

        h4 {
          color: #E9E9E9;
        }

        p {
          color: #E9E9E9;

          span {
            color: #E9E9E9;
          }
        }
      }
    }
    &:hover{
      &:after {
        content: '';
        height: 100%;
        width: 100%;
        background-color: #18153A;
        top: 0;
        left: 0;
        right: 0;
        position: absolute;
        transition: height .4s ease;
      }
    }

  }



  @media (max-width: 767px) {
    .blog-single__inner__content__top {
      p, h2 {
        left: 30px;
        right: 30px;
        top: 30px;
      }
    }

    .blog-single__inner__content__bottom h4, .blog-single__inner__content__bottom p {
      left: 30px;
      right: 30px;
      top: 30px;
    }

    .swiper-initialized {
      margin-left: 0;
      padding-right: 0;
    }

    .blog-slider {
      &__slider-wrap {
        margin-left: 15px;
        margin-right: 15px;

        .slider-nav-mobile {
          margin-top: 40px;

          ul {
            display: flex;
          }

          li {
            height: 50px;
            width: 50px;
            background-color: #000;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            cursor: pointer;

            &:nth-of-type(1) {
              margin-right: 20px;
            }

            svg {
              color: #ffffff;
              z-index: 2;
            }
          }
        }
      }
    }

    .blog-button {
      margin-bottom: 40px;

      .slider-nav {
        display: none;
      }
    }

    .slider-nav-mobile {
      display: block;
    }

  }

`;
export default RandomSliderV1;
import React, {useEffect} from 'react';
import styled from "styled-components";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import InnerBanner from "../../components/InnerBanner";
import BroucherList from "../../components/investor/BroucherList";
import PdfList from "../../components/investor/PdfList";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchInvestorDetails} from "../../api/redux/Investor";

const MyComponent = () => {

    const dispatch = useDispatch()
    const getData = useSelector(store => store.investorReducer)
    let {slug} = useParams();

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.INVESTOR_DETAILS
        dispatch(fetchInvestorDetails([api_url + `/${slug}`]))
    }, [])

    const data = getData?.posts?.[0];
    const banner = data?.banner;
    const annual = data?.annual;
    const others = data?.others;

    console.log('data', others)

    return (

        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${data?.meta?.meta_title ? data?.title  : banner?.title}`}</title>
                {
                    data?.meta?.meta_desc &&
                    <meta name="description" content={data?.meta?.meta_desc}/>

                }
                {
                    data?.meta?.og_title &&
                    <meta property="og:title" content={data?.meta?.og_title}/>

                }
                {
                    data?.meta?.og_desc &&
                    <meta property="og:description" content={data?.meta?.og_desc}/>
                }
                <meta property="og:url" content={window?.location?.href}/>
                <meta property="og:image" content={banner?.image}/>
                <meta property="og:type" content={"website"}/>
            </Helmet>

            <StyledComponent>
                <InnerBanner
                    subtitle={banner?.subtitle}
                    title={banner?.title}
                    img={banner?.image}
                />
                {
                    annual?.list?.length > 0 &&
                    <BroucherList data={annual}/>
                }

                {
                    others?.list?.length > 0 &&
                    <PdfList data={others}/>
                }


            </StyledComponent>
        </HelmetProvider>

    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;

import React, {useEffect} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";

import {
    BrowserRouter as Router,
    Switch,
    Route,
    useParams,
    useHistory
} from "react-router-dom";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import postReducer, {fetchMediaDetail, fetchPostDetail} from "../../api/redux/Media";
import {useDispatch, useSelector} from "react-redux";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import {Loading} from "../../components/Loading";
import InnerBanner from "../../components/InnerBanner";
import Description from "../../components/career/Description";
import CareerForm from "../../components/career/CareerForm";
import {fetchCareerDetail} from "../../api/redux/Career";
import {PageAnimation} from "../../components/PageAnimation";
import {motion} from "framer-motion"

const MyComponent = () => {
    const dispatch = useDispatch()
    const getData = useSelector(store => store.careerReducer)
    let {slug} = useParams();

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.CAREER_DETAILS
        dispatch(fetchCareerDetail([api_url + `/${slug}`]))
    }, [])

    const data = getData?.detail?.[0]


    console.log('data', getData)

    return (

        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${data?.post_title ? data?.post_title + ' | ' + 'Kalyar' : ''}`}</title>
                {
                    data?.meta?.meta_desc &&
                    <meta name="description" content={data?.meta?.meta_desc}/>

                }
                {
                    data?.meta?.og_title &&
                    <meta property="og:title" content={data?.meta?.og_title}/>

                }
                {
                    data?.meta?.og_desc &&
                    <meta property="og:description" content={data?.meta?.og_desc}/>
                }
                <meta property="og:url" content={window?.location?.href}/>
                <meta property="og:image" content={'/images/static/logo.svg'}/>
                <meta property="og:type" content={"website"}/>
            </Helmet>

            <StyledComponent>
                <Description title={data?.post_title} desc={data?.post_content} summary={data?.summary}
                             requirements={data?.requirements} schedule={data?.schedule} formTitle={data?.form?.title}
                             formDesc={data?.form?.desc} responsibilities={data?.responsibility}/>
                <CareerForm data={data?.form}/>
            </StyledComponent>
        </HelmetProvider>

    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;

import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import Title from "../Title";
import {Link} from "react-router-dom";
import {Img} from "../Img";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({data}) => {
    console.log('investor', data)
    return (
        <StyledComponent className={'pt-150 pb-150 reveal-up'}>
            <Container className={'invest'}>
                <Row>
                    <Col md={12}>
                        <Title text={'Earning and Financial'} margin={'0 0 60px'}/>
                    </Col>
                    {
                        data && data?.length > 0 &&
                        data?.map((e,i)=>{
                            return(
                                <Col md={4} className={'invest__single'} key={i}>
                                    <div className={'invest__single__wrap'}>
                                        <Link to={`investor/${e?.post_name}`}>
                                            <Img src={e?.thumbnails} alt={''}/>
                                            <div className={'content'}>
                                                <svg id="Component_112_2" data-name="Component 112 – 2" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                                                    <circle id="Ellipse_1" data-name="Ellipse 1" cx="20" cy="20" r="20" transform="translate(0 0)" fill="#fff"/>
                                                    <circle id="Ellipse_4" data-name="Ellipse 4" cx="1.333" cy="1.333" r="1.333" transform="translate(18.667 18.667)" fill="#f9f9f9" opacity="0"/>
                                                    <g id="Group_20" data-name="Group 20" transform="translate(11.262 23.52) rotate(-45)">
                                                        <line id="Line_11" data-name="Line 11" x2="16.512" transform="translate(0 3.691)" fill="none" stroke="#180e81" stroke-linecap="round" stroke-width="1"/>
                                                        <path id="Path_7" data-name="Path 7" d="M0,0,3.794,3.77,0,7.381" transform="translate(13.543)" fill="none" stroke="#180e81" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                                                    </g>
                                                </svg>
                                                <h4 className={''}>{reactHtmlParser(e?.post_title)}</h4>
                                            </div>
                                        </Link>
                                    </div>
                                </Col>
                            );
                        })
                    }
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`

    .invest{
      &__single{
        margin-bottom: 40px;
        &:nth-last-child(3){
          margin-bottom: 0;
        }
        &:nth-last-child(2){
          margin-bottom: 0;
        }
        &:last-child{
          margin-bottom: 0;
        }
        &__wrap{
          position: relative;
          padding-top: calc(230 / 270 * 100%);
          .content{
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            h4{
              width: 77%;
              position: absolute;
              bottom: 40px;
              left: 40px;
              color: #FFFFFF;
              padding-top: 20px;
              border-top: 1px solid rgb(255 255 255 / 78%);
            }
            svg{
              position: absolute;
              top: 40px;
              right: 40px;
              #Group_20{
                transition: 0.2s all ease-in-out;
              }
            }
          }
          &:hover{
            svg{
              #Group_20{
                transform: translate(11px, 17px) !important;
                transition: 0.2s all ease-in-out;
              }
            }
          }
        }
        @media(max-width: 767px){
          margin-bottom: 30px;
          &:nth-last-child(3){
            margin-bottom: 30px !important;
          }
          &:nth-last-child(2){
            margin-bottom: 30px !important;
          }
          &:last-child{
            margin-bottom: 0;
          }
          &__wrap{
            padding-top: calc(350 / 345 * 100%);
            .content{
              h4{
                left: 15px;
              }
            }
          }
        }
      }
    }
`;

export default MyComponent;

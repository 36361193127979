import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Col, Container, Form, Row} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";


import {Link} from "react-router-dom";
import Select, {components} from "react-select";
import {Img} from "../Img";
import moment from "moment/moment";
import Media from "../Media";
import Button from "../Button";
import SubTitle from "../SubTitle";
import {button, title} from "../../styles/globalStyleVars";
import {Loading} from "../Loading";

const MediaCenterListing = ({data, cat,search, handleCategoryItem, setSelectedCategory, loading}) => {
    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            borderRadius: 0,
            color: state.isSelected ? '#000' : '#221F1F',
            backgroundColor: state.isSelected ? '' : '#FFF',
            marginTop: 10,
            marginLeft: 2,
            fontSize: 18,
            paddingLeft: 25,
            overflowY: 'auto',
            fontfamily: button,
            // borderColor: state.isFocused ? '#FF0000' : '#CCCCCC',
        }),
        menu: (provided, state) => ({
            ...provided,
            color: '#FFF',
            backgroundColor: state.isSelected ? '' : '',
            margin: 0,
            borderRadius: 5,
            fontSize: 12,
            zIndex: 99,
            fontfamily: button,
        }),
        menuList: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#FFF' : '#FFF',
            borderRadius: 0,
            fontWeight: '500',
            color: '#FFF',
            fontSize: 18,
            paddingBottom: 25,
            fontfamily: button,
        }),

    };
    const DropdownIndicator = props => {
        return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="11.414" height="16.414" viewBox="0 0 11.414 16.414">
                        <g id="Group_21092" data-name="Group 21092" transform="translate(-340.293 -2110.293)">
                            <g id="Group_21092-2" data-name="Group 21092"
                               transform="translate(1585.5 2872.5) rotate(180)">
                                <line id="Line_3557" data-name="Line 3557" x2="5" y2="5"
                                      transform="translate(1234.5 756.5)" fill="none" stroke="#fff"
                                      stroke-linecap="round" stroke-width="1"/>
                                <line id="Line_3558" data-name="Line 3558" x1="5" y2="5"
                                      transform="translate(1239.5 756.5)" fill="none" stroke="#fff"
                                      stroke-linecap="round" stroke-width="1"/>
                            </g>
                            <g id="Group_21093" data-name="Group 21093" transform="translate(-893.5 1364.5)">
                                <line id="Line_3557-2" data-name="Line 3557" x2="5" y2="5"
                                      transform="translate(1234.5 756.5)" fill="none" stroke="#fff"
                                      stroke-linecap="round" stroke-width="1"/>
                                <line id="Line_3558-2" data-name="Line 3558" x1="5" y2="5"
                                      transform="translate(1239.5 756.5)" fill="none" stroke="#fff"
                                      stroke-linecap="round" stroke-width="1"/>
                            </g>
                        </g>
                    </svg>

                </components.DropdownIndicator>
            )
        );
    };



    //filter mapping


    const [activeItem, setActiveItem] = useState('All');
    const [selectType, setSelectType] = useState('All')
    const [filteredData, setFilteredData] = useState(data); // Initialize with all data

    const selectTypeInputRef = useRef();
    const [itemsToShow, setItemsToShow] = useState(6);
    const [isCompleted, setIsCompleted] = useState(false)

    // Handle initial selection when the component mounts
    useEffect(() => {
        handleItemClick('All');
    }, []);
    const handleType = (e) => {
        setSelectType(e);
        // Filter the data when the select dropdown changes
        filterData(e);
    };

    const handleItemClick = (item) => {
        console.log('Clicked item:', item);
        setActiveItem(item);
        // Filter the data when an item is clicked
        filterData(item);
    };

    const typeas = [
        { value: 'All', label: 'All' },
        cat?.map((item) => ({
            value: item?.value,
            label: item?.label,
        }))
    ];


    const filterData = (filter) => {
        console.log('Filter:', filter);
        if (filter === 'All') {
            setFilteredData(data); // Show all data
        } else {
            // Filter the data based on the selected category title
            setFilteredData(data.filter((item) => item?.media_cat === filter));
        }
    };

    useEffect(()=>{
        setFilteredData(data)
    },[data])

    const handleLoadMore = () => {
        setItemsToShow((prevItemsToShow) => prevItemsToShow + 6);
        if (itemsToShow + 6 >= filteredData?.length) {
            setIsCompleted(true);
        } else {
            setIsCompleted(false);
        }
    };

    return (
        <StyledComponent id={'got-to-list'} className={'reveal-up article-list pt-120 pb-120'}>
            <Container>
                <Row className={'filter_wrap'}>
                    <Col md={9}>
                        <SubTitle text={'Media Center'} color={'rgb(24 21 58 / 80%)'} borderColor={'rgb(24 21 58 / 80%)'} fontWeight={500}/>
                        <div className="filter">
                            <ul>
                                <li
                                    className={`${activeItem === 'All' ? 'active' : ''}`}
                                    onClick={() => handleItemClick('All')}
                                >
                                    <span>All</span>
                                </li>
                                {cat?.map((item, i) => (
                                    <li
                                        key={i}
                                        className={`${activeItem === item.label ? 'active' : ''}`}
                                        onClick={() => handleItemClick(item.label)}
                                    >
                                        <span>{item.label}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>

                        {/*    filter for mobile*/}

                        <div className="filter-mobile">
                            <Select
                                    styles={customStyles}
                                    components={{DropdownIndicator}}
                                    className='select-here files' placeholder='All'
                                    options={cat}
                                    ref={selectTypeInputRef}
                                    onChange={(e) => handleType(e?.label)}
                            />

                        </div>

                    </Col>
                </Row>


                <Row className={'min-height'}>
                    {
                        loading ?
                            <Loading/> :
                            <>
                                {
                                    filteredData?.length && filteredData?.map((item,index)=>{
                                        const dateObject = new Date(item?.list?.date);
                                        const getDate = dateObject && !isNaN(dateObject) ? dateObject.getDate() : null;
                                        const getMonth = dateObject && !isNaN(dateObject) ? dateObject.toLocaleString('default', { month: 'long' }) : null;
                                        const getYear = dateObject && !isNaN(dateObject) ? dateObject.getFullYear() : null;
                                        return(

                                            <Col key={index + 1} className={'profile-gap'} md={4}>
                                                <Media link={item?.post_name}
                                                       title={item?.post_title}
                                                       img={item?.list?.thumbnail}
                                                       shortDesc={item?.list?.short_desc}
                                                       getDate={getDate}
                                                       getMonth={getMonth}
                                                       getYear={getYear}
                                                />
                                            </Col>
                                        );
                                    })
                                }
                            </>
                    }
                </Row>

            </Container>
        </StyledComponent>
    );
};
const StyledComponent = styled.section`
  overflow: hidden;

  .list-loadmore {
    display: flex;
    justify-content: center;
  }

  .filter_wrap {
    .col-md-9 {
      min-width: 100%;
    }

    .filter {
      ul {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        row-gap: 30px;

        li {
          font-family: ${button};
          overflow: hidden;
          display: inline-block;
          border-radius: 50px;
          padding: 12px 36px;
          color: #1E1E2E;
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          border: 1px solid #1E1E2E;
          margin-right: 30px;
          position: relative;
          cursor: pointer;
          transition: 0.7s all ease;

          &:after {
            position: absolute;
            background: #180E81;
            width: 100%;
            height: 100%;
            content: '';
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            transition: 0.5s all ease;
            transform: translateX(-300px);
            border-radius: 21px;
            z-index: 0;
          }

          span {
            position: relative;
            z-index: 1;
            transition: 0.1s all ease;

          }

          &:last-child {
            margin-right: 0;
          }

          &.active {
            background: #180E81 !important;
            span {
              color: white !important;
            }
          }

          &:hover {
            color: white !important;
            
            span {
              color: white;
              transition: 0.5s all ease;

            }

            &:after {
              transform: scale(1);
            }
          }
        }
      }
    }
  }

  .load_more {
    margin-top: 20px;

    .dc-btn {
      display: flex;
      justify-content: center;
    }
  }
}

.min-height {
  padding-top: 80px;

  .profile-gap:nth-child(3n+3) {
    padding-bottom: 80px;
    @media (max-width: 767px) {
      padding-bottom: 60px;
    }
  }
}

.presslist {
  &__single {
    //box-sizing: border-box;
    transition: 1s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;

    //&:hover {
    //  //outline: auto;
    //  //outline-color:  #EE1B24;
    //  border: 1px solid #EE1B24;
    //  transition: 1s ease-in-out;
    //  -webkit-transition: all 0.2s ease-in-out;
    //  box-shadow: 0 5px 30px rgba(238, 27, 36, 0.08);
    //  border-radius: unset;
    //
    //}

    &__img {
      position: relative;
      padding-top: calc(200 / 370 * 100%);

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }

    }

    &__content {
      padding: 20px 0px 10px;
      position: relative;
      background-color: #F6F9F8;
      @media (max-width: 992px) {
        font-size: 18px;
        line-height: 27px;
      };


      &__link {
        position: absolute;
        bottom: 31px;
      }

      h2 {
        color: #1E1E2E;
        opacity: 70%;
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 10px;
        font-weight: 400;
      }

      h6 {
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        color: #E72E4B;
        margin-bottom: 20px;
      }

      p {
        color: #1E1E2E;
        margin-bottom: 30px;
        font-size: 20px;
        line-height: 26px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 700;

      }

      &__learn {
        position: relative;

        &:after {
          content: '';
          height: 3px;
          width: 0px;
          background-image: linear-gradient(#AD0000, #FF0000);
          position: absolute;
          transition: .3s;
        }

        h2 {
          color: #222222;
          font-weight: 500;
          cursor: pointer;
          border-bottom: 2px solid #222222;
          width: 26%;
        }
      }

    }

    @media (max-width: 767px) {
      margin-bottom: 20px !important;
      :last-child {
        margin-bottom: 0px;
      }
    }

  }
}

.profile-text {
  h6 {
    padding-top: 20px;
    color: #1E1E2E;
  }

  p {
    padding-top: 5px;
    color: #000000a8;
    font-size: 16px;
    font-weight: 400;
  }
}

.filter-mobile {
  display: none;
}

.css-1wa3eu0-placeholder, .css-1uccc91-singleValue {
  color: white !important;
  font-size: 10px;
  font-weight: 600 !important;
}

.read-btn {

  position: relative;
}

@media (min-width: 992px) {
  .min-height {
    min-height: 490px;
  }
}

@media (max-width: 767px) {
  padding-top: 60px;
  .profile-gap:not(:last-child) {
    padding-bottom: 60px;
  }

  .filter_wrap {
    flex-direction: column-reverse;

    .search {
      margin-bottom: 40px;
    }
  }

  .load_more {
    margin-top: 0;
  }

  .filter-mobile {
    display: block;
  }

  .filter {
    display: none;
  }

  .css-13cymwt-control, .css-t3ipsp-control {
    background-color: #180E81;
    border-radius: 50px;

    .css-1jqq78o-placeholder {
      color: white;
      font-size: 20px;
      line-height: 24px;
      font-weight: 700;
      margin: 10px 0 10px 30px;
    }

    .css-1fdsijx-ValueContainer {
      padding: unset;

      .css-1dimb5e-singleValue {
        margin-left: 20px;
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        color: #FFF;
      }
    }

    .css-1u9des2-indicatorSeparator {
      display: none;
    }

    .css-1xc3v61-indicatorContainer {
      padding-right: 30px;
    }
  }

  .css-t3ipsp-control {
    border-color: unset !important;
    box-shadow: unset !important;
  }

  .css-qbdosj-Input {
    input {
      min-width: 0px !important;
      opacity: 0 !important;
    }
  }

  .css-15lsz6c-indicatorContainer {
    padding-right: 30px;
    color: hsl(0, 0%, 80%);
  }

  .css-1u78dcm-menu {
    position: absolute;
    z-index: 10;
  }

  .css-yk16xz-control, .css-1pahdxg-control {
    border-radius: 18px !important;
    border-color: #989898;
    height: 40px;
    background-color: #E50019;
    cursor: pointer;
    padding-left: 15px;
  }

  .css-yk16xz-control .css-1wa3eu0-placeholder, .css-1pahdxg-control .css-1wa3eu0-placeholder, .css-1uccc91-singleValue {
    color: #ffffff !important;
    font-size: 12px !important;
    font-weight: bold;
    cursor: pointer;
  }

  .css-yk16xz-control .css-tlfecz-indicatorContainer, .css-1pahdxg-control .css-tlfecz-indicatorContainer, .css-yk16xz-control .css-1gtu0rj-indicatorContainer, .css-1pahdxg-control .css-1gtu0rj-indicatorContainer {
    margin-right: 20px;
  }
  
  .css-1dimb5e-singleValue{
    color: #ffffff;
    font-size: 16px;
    font-family: ${button};
    margin-left: 20px;
  }

`;
export default MediaCenterListing;

import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {Col, Container, Row} from 'react-bootstrap';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import "swiper/css/grid";
import {BsArrowLeft, BsArrowRight} from 'react-icons/bs';
import {Autoplay,Grid,Navigation,Pagination} from "swiper";
import SubTitle from "../SubTitle";
import LeftSvg from "../svg/LeftSvg";
import RightSvg from "../svg/RightSvg";

const HiringPartner = ({offset,data}) => {
    const [sliderNumber, setSliderNumber] = useState('1')
    const [totalNumber, setTotalNumber] = useState('1')
    const nextRef = useRef();
    const prevRef = useRef();
    const sliderRef = useRef();

    let sliderParams = {
        slidesPerView: 2,
        spaceBetween: 20,
        slidesPerGroup: 2,
        // centeredSlides: true,
        autoplay: {
            delay: 7000,
            disableOnInteraction: false
        },
        speed: 1000,

        pagination: {
            el: '.swiper-pagination',
            clickable: true
        },
        navigation: {
            nextEl: '#nextRef',
            prevEl: '#prevRef'
        },

        breakpoints: {
            // when window width is >= 320px
            768: {
                autoplay: {
                    delay: 7000,
                    disableOnInteraction: false
                },
                spaceBetween: 30,
                slidesPerView: 3,
                slidesPerGroup: 3,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
            },
            769: {
                autoplay: {
                    delay: 7000,
                    disableOnInteraction: false
                },
                spaceBetween: 30,
                slidesPerView: 3,
                slidesPerGroup: 3,
            },
            992: {
                autoplay: {
                    delay: 7000,
                    disableOnInteraction: false
                },
                spaceBetween: 30,
                slidesPerView: 5,
                slidesPerGroup: 4,
            },

        },
        modules:{Autoplay,Grid,Navigation}

    };


    useEffect(() => {
        // if (window.screen.width > 768) {
        //     document.querySelector('.as-hiring-partner .OurProject__left-text').style.paddingLeft = offset + 30 + 'px';
        // }
    }, [offset])


    return (
        <StyledProject className='as-work-force as-hiring-partner as-business pt-150 pb-150'>
            <Container className='OurProject'>

                <Row>
                    <Col className={'nav-button'}>
                        <SubTitle text={data?.subtitle} color={'rgb(24 21 58 / 80%)'} borderColor={'rgb(24 21 58 / 80%)'}/>
                        <ul className="slider-nav">
                            <li id={'prevRef'}><span><LeftSvg/></span></li>
                            <li id={'nextRef'}><span><RightSvg/></span></li>
                        </ul>
                    </Col>
                </Row>

                <Row>

                    <Col sm={12} className='as-work-force__slider'>
                        <div className="project-slider">
                            <Swiper   spaceBetween={20}
                                      slidesPerView={2}
                                      slidesPerGroup={2}
                                      allowSlideNext={true}
                                      allowSlidePrev={true}
                                      allowTouchMove={true}
                                      longSwipesMs={900}
                                      speed={1000}
                                      navigation={
                                          {
                                              nextEl: '#nextRef',
                                              prevEl: '#prevRef'
                                          }
                                      }
                                      pagination={
                                          {
                                              el: '.swiper-pagination',
                                              clickable: true
                                          }
                                      }
                                      autoplay={{
                                          delay: 7000,
                                          disableOnInteraction: false,
                                      }}
                                      breakpoints={{
                                          768: {
                                              // autoplay: {
                                              //     delay: 7000,
                                              //     disableOnInteraction: false
                                              // },
                                              spaceBetween: 30,
                                              slidesPerView: 3,
                                              slidesPerGroup: 3,
                                              pagination: {
                                                  el: '.swiper-pagination',
                                                  clickable: true
                                              },
                                          },
                                          769: {
                                              // autoplay: {
                                              //     delay: 7000,
                                              //     disableOnInteraction: false
                                              // },
                                              spaceBetween: 30,
                                              slidesPerView: 3,
                                              slidesPerGroup: 3,
                                          },
                                          992: {
                                              // autoplay: {
                                              //     delay: 7000,
                                              //     disableOnInteraction: false
                                              // },
                                              spaceBetween: 30,
                                              slidesPerView: 5,
                                              slidesPerGroup: 4,
                                          },

                                      }}
                                      modules={[Autoplay,Grid,Navigation,Pagination]}
                                // navigation={true} modules={[Navigation]}
                                      onSwiper={(swiper) => console.log(swiper)}


                                      ref={sliderRef}>

                                {data?.all_valued_clients?.length > 0 && data?.all_valued_clients?.map((i,index)=>(
                                <SwiperSlide key={index}>
                                    <div className="single-box">
                                        <div className="single-box__inner">
                                            <img src={i?.svg} alt=""/>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                ))}

                            </Swiper>
                        </div>
                    </Col>

                </Row>
            </Container>
        </StyledProject>
    )

}

const StyledProject = styled.section`
  position: relative;
  background-color: #F9F9F9;

  .slider-nav {
    top: -20px;
    position: absolute;
    right: 15px;

    li {
      display: inline-block;
      cursor: pointer;
      height: 40px;
      width: 40px;

      &:nth-of-type(1) {
        margin-right: 20px;
      }

      span {
        height: 40px;
        width: 40px;
        background: #E9E9E9;
        border: 1px solid #E9E9E9;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        transition: all .3s ease;

        &:after {
          content: '';
          height: 0;
          width: 0;
          //bg color will be hover color
          background-color: inherit;
          position: absolute;
          border-radius: 50%;
          transition: all .3s ease;
        }

        svg {
          font-size: 20px;
          transition: color .4s ease;
          position: relative;
          z-index: 3;
          color: #5D4E4D;
        }

        &:hover {
          border-color: transparent;

          &:after {
            height: 100%;
            width: 100%;
          }

          svg {
            color: #FFF;
          }
        }
      }
    }

    .swiper-button-disabled {
      opacity: 1 !important;
    }

  }

  .swiper-button-next, .swiper-button-next {
    opacity: 0;
  }

  .OurProject__left-text {
    padding-right: 70px;
    position: relative;

    p {
      margin: 0;
      font-size: 16px;
      font-weight: 400;
      font-style: italic;
      line-height: 22px;
    }

    .nav-button {
      position: relative;
    }


  }

  .swiper-pagination {
    opacity: 0 !important;
  }


  .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 7px;
  }

  .swiper-pagination-bullet {
    border: 1px solid #7F7F7F;
    background-color: transparent;
    opacity: 1;

    &.swiper-pagination-bullet-active {
      height: 7px;
      width: 7px;
      border: 1px solid #BEC7C7;
    }
  }

  .project-slider {
    .single-box {
      &__inner {
        padding-top: 100%;
        border: 1px solid #E9E9E9;
        position: relative;

        img {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          max-width: 75%;
          margin: auto;
          object-fit: contain;
        }
      }
    }
  }


  @media (max-width: 768px) {

    .OurProject__left-text {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .as-work-force__slider {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .swiper-pagination {
      opacity: 1 !important;
    }

    .title {
      br {
        display: none;
      }
    }

    .sub-title {
      margin-bottom: 30px;

      br {
        display: none;
      }
    }

    .swiper-container {
      padding-bottom: 60px;
    }

  }

  @media (max-width: 1100px) {
    .title {
      font-size: 30px;
      line-height: 32px;
    }
  }

  @media (max-width: 767px) {
    .nav-button{
      .sub-title{
        h5{
          margin-bottom: 40px;
        }
      }
    }
    .slider-nav {
      margin-bottom: 40px;
      position: unset;
    }
  }


`;

export default HiringPartner;
import React, {useEffect} from 'react';
import styled from "styled-components";
import {Link, useLocation, useParams} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import InnerBanner from "../../components/InnerBanner";
import Navigation from "../../components/technology/Navigation";
import Converting from "../../components/technology/Converting";
import Printing from "../../components/technology/Printing";
import Finishing from "../../components/technology/Finishing";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchTechnologyReplica} from "../../api/redux/TechnologyReplica";
import {fetchTechnologyPackaging} from "../../api/redux/TechnologyPackaging";
import {motion} from "framer-motion";
import {PageAnimation} from "../../components/PageAnimation";



const MyComponent = () => {

    //scroll to section
    const location = useLocation()

    useEffect(() => {
        window.scroll(0, 0)
    }, [])
    useEffect(() => {
        if (location.hash) {
            const targetElement = document.querySelector(location.hash);

            if (targetElement) {
                const offsetTop = targetElement.getBoundingClientRect().top + window.scrollY;

                window.scrollTo({
                    top: offsetTop,
                    behavior: "smooth",
                });
            }
        }
    }, [location]);


    const url = location.pathname.split('/')[1];


    const dispatch = useDispatch()

    let getPost;

    useEffect(() => {
        let api_url;

        if (url === 'kalyar-replica') {
            api_url = apiEndPoints.TECHNOLOGY_REPLICA;
            dispatch(fetchTechnologyReplica([api_url]));
        } else {
            api_url = apiEndPoints.TECHNOLOGY_PACKAGING;
            dispatch(fetchTechnologyPackaging([api_url]));
        }
    }, [dispatch, url]);

    getPost = useSelector(state => {
        if (url === 'kalyar-replica') {
            return state.technologyReplicaReducer;
        } else {
            return state.technologyPackagingReducer;
        }
    });


    //refactor
    let data = getPost?.posts?.page_data?.[0];
    let banner = data?.banner;
    let nav = data?.nav;

    console.log('nav', nav)

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${data?.meta?.meta_title ? data?.meta?.meta_title + ' | ' + data?.banner?.subtitle : data?.banner?.title + " | " + data?.banner?.subtitle }`}</title>
                {
                    data?.meta?.meta_desc &&
                    <meta name="description" content={data?.meta?.meta_desc}/>

                }
                {
                    data?.meta?.og_title &&
                    <meta property="og:title" content={data?.meta?.og_title}/>

                }
                {
                    data?.meta?.og_desc &&
                    <meta property="og:description" content={data?.meta?.og_desc}/>
                }
                <meta property="og:url" content={window?.location?.href}/>
                <meta property="og:image" content={banner?.image}/>
                <meta property="og:type" content={"website"}/>
            </Helmet>

            <StyledComponent>

                    <InnerBanner
                        title={banner?.title}
                        img={banner?.image}
                        subtitle={banner?.subtitle}
                    />

                    <Navigation data={nav}/>

                    <Converting data={nav?.converting}/>

                    <Printing data={nav?.printing}/>

                    <Finishing data={nav?.finishing}/>

            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;

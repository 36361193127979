import React, {useEffect, useState} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import styled from "styled-components";
import Button from "../Button";
import Select, {components} from "react-select";
import {button, hover, title} from "../../styles/globalStyleVars";
import {Link} from "react-router-dom";

// animation
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
import ReactHtmlParser from "react-html-parser";
import reactHtmlParser from "react-html-parser";

gsap.registerPlugin(ScrollTrigger);

const JobList = ({data, setSelectDepartment, filter}) => {


    ScrollTrigger.refresh()
    // animation
    useEffect(() => {
        let allAnim = document.querySelectorAll('.fade-up');

        allAnim.forEach((el, index) => {
            gsap.fromTo(el, {
                autoAlpha: 0,
                y: 50,
                ease: "none",
            }, {
                y: 0,
                autoAlpha: 1,
                ease: "power2",
                duration: 1,
                scrollTrigger: {
                    id: `${index + 1}`,
                    trigger: el,
                    // start: 'top center+=100',
                    toggleActions: 'play none none reverse',
                }
            })
        })
    }, [])


    // dropdown style
    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            borderRadius: 0,
            color: state.isSelected ? '#FFF' : '#262630',
            backgroundColor: state.isSelected ? '#180E81' : '#ffffff',
            margin: 0,
            cursor: 'pointer',
            fontSize: '14px',
            lineHeight: '20px',
            fontWeight: '500',
            fontfamily: `${button}`,
            "&:hover": {
                backgroundColor: `#ffffff`,
                color: '#25292C',
                cursor: 'pointer'
            },
        }),
        menu: (provided, state) => ({
            ...provided,
            color: 'rgba(0,0,0,0.5)',
            backgroundColor: state.isSelected ? 'rgba(0,0,0,0)' : 'rgba(255,255,255,0)',
            margin: 0,
            zIndex: 9999,
        }),
        menuList: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#000' : '#FFF',
            borderRadius: 0,
            cursor: 'pointer',
            zIndex: 9999,
        }),

    };

    const departments = filter?.department?.map((item) => {
        return {
            label: reactHtmlParser(item?.label),
            value: item?.value
        }
    })

    const DropdownIndicator = props => {
        return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    {props.selectProps.menuIsOpen ?
                        <svg xmlns="http://www.w3.org/2000/svg" width="11.732" height="7.003"
                             viewBox="0 0 11.732 7.003">
                            <g id="Group_24047" data-name="Group 24047" transform="translate(18853.867 -10435.998)">
                                <g id="Group_24046" data-name="Group 24046"
                                   transform="translate(-18852.863 10442.258) rotate(-93)">
                                    <line id="Line_12380" data-name="Line 12380" x2="5" y2="5" fill="none"
                                          stroke="#ffffff" stroke-linecap="round" stroke-width="1"/>
                                    <line id="Line_12381" data-name="Line 12381" y1="5" x2="5"
                                          transform="translate(0 5)" fill="none" stroke="#ffffff" stroke-linecap="round"
                                          stroke-width="1"/>
                                </g>
                            </g>
                        </svg>

                        :

                        <svg xmlns="http://www.w3.org/2000/svg" width="11.414" height="6.414"
                             viewBox="0 0 11.414 6.414">
                            <g id="Group_22451" data-name="Group 22451" transform="translate(10.707 0.707) rotate(90)">
                                <line id="Line_12380" data-name="Line 12380" x2="5" y2="5" fill="none" stroke="#ffffff"
                                      stroke-linecap="round" stroke-width="1"/>
                                <line id="Line_12381" data-name="Line 12381" y1="5" x2="5" transform="translate(0 5)"
                                      fill="none" stroke="#ffffff" stroke-linecap="round" stroke-width="1"/>
                            </g>
                        </svg>
                    }

                </components.DropdownIndicator>
            )
        );
    };

    console.log('career', data);

    return (
        <StyledJobList className='joblist reveal-up pt-150 pb-150'>
            <>
                {
                    data ?
                        <Container>
                            <Row className='joblist__select'>
                                <Col md={6} lg={4} xs={12} className=''>
                                    <Select components={{DropdownIndicator}}
                                            styles={customStyles}
                                            onChange={(selectedOption) => setSelectDepartment(selectedOption)}
                                            classNamePrefix={'custom'} className='select-here files'
                                            placeholder={'Select Department'}
                                            options={departments}/>
                                </Col>
                            </Row>
                            <Row>
                                {
                                    (data || [])?.map((item, index) => {
                                        return (
                                            <Col  key={index} md={6} lg={4} xs={12} className='joblist__single'>
                                                <div className='joblist__single__box'>
                                                    <p>Deadline: {item?.list?.deadline}</p>
                                                    <h6>{item?.post_title}</h6>
                                                    <p>
                                                        <span>Experience: {item?.list?.experience}</span><br/>
                                                        <span>Location: {item?.list?.location}</span><br/>
                                                        <span>Education: {item?.list?.education}</span>
                                                    </p>

                                                    <div className="dc-link">
                                                        <Link to={`/career/${item?.post_name}`}>
                                                            <div className="hover-btn hover">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                                                                    <g id="Group_19016" data-name="Group 19016" transform="translate(-645.5 -6884.5)">
                                                                        <line
                                                                            id="Line_12372"
                                                                            data-name="Line 12372"
                                                                            y2="10"
                                                                            transform="translate(651.5 6885.5)"
                                                                            fill="none"
                                                                            stroke="#ffffff"
                                                                            stroke-linecap="round"
                                                                            stroke-width="2"
                                                                        />
                                                                        <line
                                                                            id="Line_12373"
                                                                            data-name="Line 12373"
                                                                            y2="10"
                                                                            transform="translate(656.5 6890.5) rotate(90)"
                                                                            fill="none"
                                                                            stroke="#ffffff"
                                                                            stroke-linecap="round"
                                                                            stroke-width="2"
                                                                        />
                                                                    </g>
                                                                </svg>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </Col>
                                        )
                                    })
                                }


                            </Row>
                            {/*<Row className='joblist__load'>*/}
                            {/*    <div className='joblist__load__btn'>*/}
                            {/*        <Button text={'Load More'} background={'#180E81'} color={'#f9f9f9'}/>*/}
                            {/*    </div>*/}
                            {/*</Row>*/}

                        </Container>
                        :
                        <Container>
                            <h2>We are not hiring at the moment...</h2>
                        </Container>
                }
            </>


        </StyledJobList>
    );
};
const StyledJobList = styled.section`
  //animation: draw-line 0.25s ease-in-out;
  @keyframes draw-line {
    0% {
      background-size: 0 2px;
    }
    to {
      background-size: 100% 2px;
    }
  }

  .dc-link{
    margin-top: 30px;
    .hover-btn {
      height: 40px;
      width: 40px;
      background-color: #180E81;
      border-radius: 50%;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      z-index: 2;
      inset: 0px;

      &.hover:after {
        border-radius: 50%;
      }

      svg {
        position: relative;
        z-index: 2;
      }

      path {
        transition: all 0.3s ease;
      }

      g {
        line {
          stroke: #ffffff;
        }
      }
    }

    &:hover {
      .hover-btn {
        &:after {
          height: 100%;
          width: 100%;
        }

        path {
          fill: #fff;
        }
        g {
          line {
            stroke: #ffffff;
          }
        }
      }
    }
  }
  .joblist {
    &__single {
      margin-bottom: 30px;

      &__box {
        background-color: #F2F2F2;
        padding: 40px 30px 40px 30px;
        height: 100%;
        position: relative;

        p {
          font-size: 14px;
          line-height: 18px;
          font-weight: 300;
          color: rgb(38 38 48 / 50%);
          margin-bottom: 10px;
          font-family: ${button};
        }

        h6 {
          font-size: 20px;
          line-height: 26px;
          font-weight: 500;
          font-family: ${button};
          color: #18153A;
          margin-bottom: 40px;
        }

        span{
          font-size: 16px;
          line-height: 24px;
          font-weight: 400;
          color: rgb(38 38 48 / 50%);
          margin-bottom: 10px;
          font-family: ${button};
          &:last-child{
            margin-bottom: 0 !important;
          }
        }
        &__lower {
          margin-top: 40px;
          margin-bottom: 38px;
          min-height: 80px;
          padding-bottom: 38px;

          @media (max-width: 1165px) {
            min-height: 100% !important;
          }

          h2 {
            font-weight: 400;
            margin-bottom: 10px;
          }
        }

        &__btn {
          height: 40px;
          width: 40px;
          background: #E9E9E9;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          bottom: 40px;
          transition: all .3s ease;
          background-image: linear-gradient(#AD0000, #FF0000);

          svg {
            font-size: 20px;
            transition: all .4s ease;
            position: relative;
            z-index: 3;
            //color: #5D4E4D;
          }

          .btn-hover {
            transition: all .4s ease;
          }

          &:hover {
            border-color: transparent;
            cursor: pointer;

            .btn-hover {
              transition: all .4s ease;
              r: 20px;
            }

          }

        }
      }

      @media (max-width: 767px) {
        :last-child {
          margin-bottom: 0px;
        }
      }

    }

    &__load {
      margin-top: 60px;
      display: flex;
      justify-content: center;
      align-items: center;

      &__btn {
        .dc-btn {
          svg {
            transform: rotate(90deg);
          }
        }
      }
    }

    &__select {
      margin-bottom: 60px;
      display: flex;
      justify-content: start;
      align-items: center;

      @media (max-width: 767px) {
        margin-bottom: 60px;
      }
    }

  }

  //caret
  .custom__control {
    background-color: #180E81;
    border-radius: 25px;
    box-shadow: none;
    outline: none !important;
    cursor: pointer;
    //margin-bottom: 60px
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    font-family: ${button};
    padding: 12px 20px;

    .custom__placeholder, .custom__single-value {
      color: #ffffff;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      font-family: ${button} !important;
    }

    .custom__value-container {
      padding-left: 0;
    }
    .custom__dropdown-indicator{
      padding: 0;
    }

    &--is-focused {

    }
  }
  
  
  h2{
    color: ${hover};
  }

`
export default JobList;

import React, {useEffect} from 'react';
import styled from "styled-components";
import {Link, useLocation, useParams} from "react-router-dom";
// import required modules
// Import Swiper styles
import {Pagination, A11y} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {button} from "../../styles/globalStyleVars";

const Category = ({data}) => {

    const location = useLocation()
    const url = location.pathname;


    useEffect(() => {
        if (location.hash) {
            const targetElement = document.querySelector(location.hash);

            if (targetElement) {
                const offsetTop = targetElement.getBoundingClientRect().top + window.scrollY;

                window.scrollTo({
                    top: offsetTop,
                    behavior: "smooth",
                });
            }
        }
    }, [location]);

    return (
        <StyledCategory className='categories reveal-up'>
            <ul>
                {
                    data && data?.length > 0 &&
                    <Swiper
                        modules={[Pagination, A11y]}
                        spaceBetween={0}
                        slidesPerView={3} // Set the initial number of slides visible
                        slidesPerGroup={3} // Set the number of slides in one group
                        pagination={{ clickable: true }}
                        onSwiper={(swiper) => console.log(swiper)}
                        onSlideChange={() => console.log('slide change')}
                        breakpoints={{
                            768: {
                                slidesPerView: 3,
                                slidesPerGroup: 3,
                                spaceBetween: 0,
                            },
                            1024: {
                                slidesPerView: 6,
                                slidesPerGroup: 6,
                                spaceBetween: 0,
                            },
                        }}
                    >
                        {
                            data?.map((e,i)=>{
                                return(
                                    <SwiperSlide>
                                        <li>
                                            <Link to={`#${e?.button}`}>
                                                <div className='wrapper'>
                                                    <img src={e?.svg_hover} alt='Food Packaging' />
                                                    <p>{e?.title}</p>
                                                </div>
                                            </Link>
                                        </li>
                                    </SwiperSlide>
                                );
                            })
                        }
                    </Swiper>
                }

            </ul>
        </StyledCategory>
    );
};

const StyledCategory = styled.section`
  padding-top: 100px;
  padding-bottom: 100px;
  ul {
    width: 100%;
    
    .swiper-slide {
      width: 228px;
      height: 228px;
      border-right: 0.5px solid #707070;
      border-top: 0.5px solid #707070;
      border-bottom: 0.5px solid #707070;
      &:first-child {
        border-left: 0.5px solid #707070;
      }
      li {
        width: 100%;
        height: 100%;
        padding-top: 64px;
        padding-bottom: 64px;
        .wrapper {
          height: 100%;
          width: 100%;
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          img {
            max-width: 100px;
            max-height: 100px;
            object-fit: contain;
          }
          p {
            position: absolute;
            color: black;
            font-family: ${button};
            font-size: 18px;
            line-height: 24px;
            font-weight: 500;
          }
        }
      }
      @media (max-width: 767px) {
        width: 115px;
        height: 115px;
        li {
          padding-top: 28px;
          padding-bottom: 27px;
          .wrapper {
            img {
              max-width: 50px;
              max-height: 60px;
            }
            p {
              width: 50%;
              text-align: center;
              font-size: 14px;
              line-height: 20px;
            }
          }
        }
      }
    }
  }

  .swiper-pagination{
    bottom: 0 !important;
  }
  .swiper-pagination-bullet {
    width: 10px !important;
    height: 10px;
    border-radius: 50% !important;
    background-color: #262630 !important;
    transition: 0.2s all ease-in-out;
    opacity: 1 !important;
  }

  .swiper-pagination-bullet-active {
    width: 6px !important;
    height: 6px;
    border-radius: 50% !important;
    background-color: #262630 !important;
    transition: 0.2s all ease-in-out;
  }
  @media (max-width: 767px) {
    padding-top: 80px;
    padding-bottom: 80px;
    .swiper {
      margin-right: 15px;
      margin-left: 15px;
      padding-bottom: 40px;
    }
    .swiper-slide {
      width: 33% !important;
    }
  }
  
  .swiper-wrapper{
    justify-content: center;
  }
`;

export default Category;
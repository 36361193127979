import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {Col, Container, Modal, Row} from "react-bootstrap";
import {Img} from "../Img";
import ReactHtmlParser from "react-html-parser";
import Button from "../Button";
import {gsap} from "gsap";
import PopupV3 from "../PopupV3";
import reactHtmlParser from "react-html-parser";
import {book, button} from "../../styles/globalStyleVars";
const BodV4 = ({name,deg,info,reverse,padding,img,last,id,desc,data}) => {
    const [show, setShow] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);

    const handleClose = () => {
        setShow(false);
        // setSelectedRow(null); // Clear the selected team member data when closing the modal
    };
    const handleShow = (teamMember) => {
        setSelectedRow(teamMember); // Set the selected team member data
        setShow(true);
    };

    console.log('row', selectedRow)


    const rows = data?.length > 0
        ? data.map((i, index) => ({
            title: i?.name,
            designation: i?.designation,
            content: i?.description,
            imageSrc: i?.image,
        }))
        : [];



    return (
        <StyledBodV4 id={`${id ? id : "MsgList"}`} className={`msg_list reveal-up pt-150 pb-150 ${padding ? padding : ''} `}>

            <Container className={last ? 'last' : ''}>
                {
                    rows.map((row,index)=>{
                        return(
                            <Row key={index + 1} className={`values ${index % 2 === 1 ? 'even-row' : 'odd-row'}`}>
                                <Col md={index % 2 === 1 ? {span:7,offset:1} : 7} className={'content_right'}>
                                    <div className="content">
                                        <h3 className="fade-up">{reactHtmlParser(row?.title)}</h3>
                                        <p className="deg fade-up">{reactHtmlParser(row?.designation)}</p>

                                        <div className="info">
                                            <p>{reactHtmlParser(row?.content)}</p>
                                        </div>
                                        <div className="modalbutton" onClick={() => handleShow(row)}>
                                            <Button  margin={'40px 0 0'} color={'#262630'} borderColor={'#262630'} text={'Read More'}/>

                                        </div>

                                    </div>
                                </Col>
                                <Col  md={index % 2 === 1 ? 4 : {span: 4, offset: 1} } className={''}>
                                    <div className="image_box_wrapper reveal">
                                        <Img src={row?.imageSrc}/>
                                    </div>
                                </Col>
                            </Row>
                        );
                    })
                }

            </Container>

         <PopupV3
             show={show}
             handleClose={handleClose}
             title={selectedRow?.title}
             designation={selectedRow?.designation}
             description={selectedRow?.content}
             img={selectedRow?.imageSrc}
         />
        </StyledBodV4>
    )
};

const StyledBodV4 = styled.div`
  
  .container {
    //border-bottom: 1px solid rgba(79, 97, 107, 0.2);
    position: relative;
  }

  .values{
    padding-bottom: 80px;
    border-bottom: 1px solid rgb(121 121 151 / 30%);
    margin-bottom: 80px;
    &:nth-child(even){
      flex-direction: row-reverse;
    }
    &:last-child{
      padding-bottom: 0;
      border-bottom: unset;
      margin-bottom: 0;
    }
    
    @media(max-width: 767px){
      flex-direction: column-reverse;
      &:nth-child(even){
        flex-direction: column-reverse;
      }
    }
  }
  
  .image_box_wrapper {
    position: relative;
    padding-top: calc(454 / 400 * 100%);

  }

  .content_right {
    @media(max-width: 767px){
      margin-top: 40px;
    }
    .content {
      //padding-left: 30px;

      @media(max-width: 767px){
        padding-left: 0px;
      }
      h3 {
        color: #180E81;
        margin-bottom: 10px;
      }

      .deg {
        color: rgba(0, 0, 0, 0.7);
        font-family: ${button};
        font-size: 16px;
        font-weight: ${book};
        line-height: 24px;
      }

      .info {
        margin-top: 40px;
        p {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          color: rgb(38 38 48);
          margin-bottom: 24px;
          display: -webkit-box;
          -webkit-line-clamp: 9;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          &:last-child{
            margin-bottom: 0;
          }
        }
      }
    }
  }
  
  .reveal {
    overflow: hidden;

    img {
      transform-origin: left;
      inset: 0px;
    }
  }
`;


export default BodV4;
